/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Contains global configuration for API services
 */
@Injectable()
export class ApiConfiguration {

  rootUrl: string = "https://xebuytthongminh.vn/api/v1";
  urlSocket: string = "https://node.busmap.com.vn:2399";


  //url = 'https://node.busmap.com.vn:2399'; //live
  //url = 'https://preprod.busmap.com.vn:2300'; //preprod
  //url = 'https://beta.busmap.com.vn:2309'; //beta

  public getStrUrlSocket() {
    return this.urlSocket;
  }
}
